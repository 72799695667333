import { Avatar, Card, CardContent, Grid, Typography } from "@material-ui/core";
import { DashboardIcon, UsageIcon } from "../Icons";
import "./card.css";
import { Button } from "react-admin";
import React from "react";
import { Link } from "react-router-dom";
export const ProfileCard = () => {
  return (
    // <Paper elevation={6}>
    <Card className="blueprint-card">
      <CardContent>
        <Grid container spacing={6}>
          <Grid item xs={6} md={6}>
            <Typography align="left" variant="h6">
              #25
            </Typography>
          </Grid>

          <Grid item xs={6} md={6}>
            <Typography align="right" variant="h6">
              543
            </Typography>
          </Grid>
          <Grid container justifyContent="center">
            <div className="profile-avatar">
              <img
                src="/favicon.png"
                style={{ borderRadius: "50%" }}
                height={64}
              />
              <Typography align="center" variant="h4">
                jimmy
              </Typography>
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={6}>
          <Grid item xs={6} md={6}>
            <Typography align="center" variant="h6">
              00:45:25
            </Typography>
          </Grid>

          <Grid item xs={6} md={6}>
            <Typography align="center" variant="h6">
              100
            </Typography>
          </Grid>

          <Grid item xs={6} md={6}>
            <Typography align="center" variant="h6">
              327
            </Typography>
          </Grid>

          <Grid item xs={6} md={6}>
            <Typography align="center" variant="h6" color={"secondary"}>
              +60%
            </Typography>
          </Grid>

          <Grid item xs={6} md={6}>
            <Typography align="center" variant="h6">
              327
            </Typography>
          </Grid>

          <Grid item xs={6} md={6}>
            <Typography align="center" variant="h6" color={"secondary"}>
              +60%
            </Typography>
          </Grid>
        </Grid>
        <Typography align="center" variant="body2">
          <Link to="/blueprints-market">View All BluePrints</Link>
        </Typography>
      </CardContent>
    </Card>
    // </Paper>
  );
};
