import React, { useState } from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
} from "@material-ui/core";

const Wallet = () => {

  return (
    <div className="dashboard-container">
      <Typography variant="h5" className="dashboard-header">
        Wallet
      </Typography>

      <Grid container spacing={4}>
        <Grid item xs={12} md={4}>
          <Card className="hedge-card">
            <CardContent></CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={4}>
          <Card className="hedge-card">
            <CardContent>
             
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={4}>
          <Card className="hedge-card">
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Instructions
              </Typography>

              <Typography variant="subtitle1" gutterBottom>
                Staking
              </Typography>
              <Typography variant="body2" paragraph>
                Higher the stake, more confident you are in your prediction
              </Typography>

              <Typography variant="subtitle1" gutterBottom>
                Expiry
              </Typography>
              <Typography variant="body2" paragraph>
                Set expiry of your blueprints to your discretion
              </Typography>

              <Typography variant="subtitle1" gutterBottom>
                Price limits
              </Typography>
              <Typography variant="body2" paragraph>
                Set the minimum and maximum order price limits that you feel
                should be followed
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default Wallet;
