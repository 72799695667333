import React, { useState } from 'react';
import { Drawer, Button, TextField, Typography, Box } from '@material-ui/core';

const RightSidePanel = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleDrawer = (open: boolean) => () => {
    // if (
    //   event.type === 'keydown' &&
    //   ((event as React.KeyboardEvent).key === 'Tab' ||
    //     (event as React.KeyboardEvent).key === 'Shift')
    // ) {
    //   return;
    // }
    setIsDrawerOpen(open);
  };

  return (
    <div>
      {/* Floating Button */}
      <Button
        variant="contained"
        color="primary"
        onClick={toggleDrawer(true)}
        style={{
          position: 'fixed',
          right: '20px',
          top: '50%',
          transform: 'translateY(-50%)',
          zIndex: 1000,
        }}
      >
        HedgeAI
      </Button>

      {/* Right Side Drawer */}
      <Drawer anchor="right" open={isDrawerOpen} onClose={toggleDrawer(false)}>
        <Box
          role="presentation"
        //   onClick={toggleDrawer(false)}
        //   onKeyDown={toggleDrawer(false)}
          style={{
            width: '300px',
            padding: '20px',
          }}
        >
          {/* Chat Interface */}
          <Typography variant="h6" gutterBottom>
            Welcome to HedgeAI!
          </Typography>
          <Typography variant="body1" gutterBottom>
            What would you like to do first?
          </Typography>
          <TextField
            label="Enter your action"
            fullWidth
            variant="outlined"
            autoFocus
          />
          <Button
            variant="contained"
            color="primary"
            fullWidth
            style={{ marginTop: '10px' }}
          >
            Submit
          </Button>
        </Box>
      </Drawer>
    </div>
  );
};

export default RightSidePanel;
