import React from "react";

interface HedgeIconProps extends React.SVGProps<SVGSVGElement> {}

export const HedgeIcon: React.FC<HedgeIconProps> = (props) => {
  return (
    <svg
      viewBox="0 0 422 413"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M171.2 412.63C170.75 412.23 170.65 412.14 170.2 411.75C172.57 401.1 174.63 390.37 177.43 379.83C187.17 343.11 197.16 306.45 207.09 269.77C210.44 257.39 213.9 245.04 217.48 232.07C233.4 228.1 249.02 224.2 266.56 219.83C254.9 263.42 243.76 305.07 232.18 348.37C249.82 346.07 265.04 339.83 280.82 336.11C296.87 332.33 312.72 327.73 328.88 323.42C343.26 269.94 357.46 217.12 371.93 163.27C348.97 140.36 325.89 117.32 301.53 93.0197C293.15 118.15 288.19 142.63 280.6 166.67C264.9 170.46 249.56 174.15 231.92 178.41C247.37 120.11 262.36 64.0097 277.5 8.47973L421.25 151.92C403.13 219.41 384.4 289.19 365.53 359.49C357.13 361.9 349.14 364.32 341.08 366.48C313.23 373.91 285.34 381.2 257.48 388.63C230.15 395.93 202.84 403.32 175.53 410.72C174.03 411.12 172.67 412.05 171.2 412.63ZM0 263.37C1.91 265.01 3.92 266.54 5.7 268.3C48.56 310.94 91.39 353.61 134.25 396.25C136.33 398.32 138.63 400.16 142.26 403.39C157.1 348.19 171.47 294.73 186.28 239.64C168.87 243.94 153.02 247.86 137.49 251.7C131.26 274.29 125.35 295.69 119.24 317.86C95.77 294.36 73.32 271.88 50.66 249.2C65.06 195.57 79.32 142.49 93.69 88.9897C124.51 80.6997 154.93 72.5197 187 63.8897C183.86 80.2897 179.41 94.5497 175.53 108.95C171.6 123.52 167.86 138.13 164.06 152.74C160.31 167.15 155.17 181.25 152.75 198.12C170.13 193.7 185.62 189.77 200.79 185.91C217.43 123.93 233.68 63.3897 250.44 0.969727C184.43 18.4797 120.43 35.4597 56.21 52.4997C53.02 64.1797 49.95 75.1797 47 86.2097C32.78 139.38 18.61 192.57 4.34 245.73C3.19 250.02 1.46 254.15 0 258.35C0 260.01 0 261.69 0 263.37Z"
        fill="white"
      />
    </svg>
  );
};
