import withIconProps from './withIconProps';
import { ChevronDown as ChevronDownBase } from "./ChevronDown";
import { ChevronUp as ChevronUpBase } from "./ChevronUp";
import { DashboardIcon as DashboardIconBase } from "./DashboardIcon";
import { TicketIcon as TicketIconBase } from "./TicketIcon";
import { UsageIcon as UsageIconBase } from "./UsageIcon";
import { WaterMeterIcon as WaterMeterIconBase } from "./WaterMeterIcon";
import { RoleIcon as RoleIconBase } from "./RoleIcon";
import { UserIcon as UserIconBase } from "./UserIcon";
import { AlertIcon as AlertIconBase } from "./AlertIcon";
import { SettingsIcon as SettingsIconBase } from "./SettingsIcon";
import { DocumentIcon as DocumentIconBase } from "./DocumentIcon";
import { SupplierCustomerIcon as SupplierCustomerIconBase } from "./SupplierCustomerIcon";
import { ProductIcon as ProductIconBase } from "./ProductIcon";
import { PaymentIcon as PaymentIconBase } from "./PaymentIcon";
import { LogsIcon as LogsIconBase } from "./LogsIcon";
import { ChartLineIcon as ChartLineIconBase } from "./ChartLineIcon";
import { WaterIcon as WaterIconBase } from "./WaterIcon";
import { BullhornIcon as BullhornIconBase } from "./BullhornIcon";
import { CogIcon as CogIconBase } from "./CogIcon";
import { DatabaseIcon as DatabaseIconBase } from "./DatabaseIcon";
import { AlertsIcon as AlertsIconBase } from "./AlertsIcon";
import { StatusIcon as StatusIconBase } from "./StatusIcon";
import { FireIcon as FireIconBase } from './FireIcon';
import { DotsIcon as DotsIconBase } from './DotsIcon';
import { StarIcon as StarIconBase } from './StarIcon';
import { LogoutIcon as LogoutIconBase } from './LogoutIcon';
import { NoteBookIcon as NoteBookIconBase } from './NoteBookIcon';
import { NoteBookIconServer as NoteBookIconServerBase } from './NoteBookIconServer';
import { AddIcon as AddIconBase } from './AddIcon';
import { ClockIcon as ClockIconBase } from './ClockIcon';
import { WalletIcon as WalletIconBase } from './WalletIcon';
import { LogoIcon as LogoIconBase } from './Logo';
import { CartIcon as CartIconBase } from './CartIcon';
import { ExchangeIcon as ExchangeIconBase } from './ExchangeIcon';
import { PencilIcon as PencilIconBase } from './PencilIcon';
import { RobotIcon as RobotIconBase } from './RobotIcon';
import { HedgeIcon as HedgeIconBase } from './HedgeIcon';








// Apply the HOC to each icon
const ChevronDown = withIconProps(ChevronDownBase);
const ChevronUp = withIconProps(ChevronUpBase);
const DashboardIcon = withIconProps(DashboardIconBase);
const TicketIcon = withIconProps(TicketIconBase);
const UsageIcon = withIconProps(UsageIconBase);
const WaterMeterIcon = withIconProps(WaterMeterIconBase);
const RoleIcon = withIconProps(RoleIconBase);
const UserIcon = withIconProps(UserIconBase);
const AlertIcon = withIconProps(AlertIconBase);
const SettingsIcon = withIconProps(SettingsIconBase);
const DocumentIcon = withIconProps(DocumentIconBase);
const SupplierCustomerIcon = withIconProps(SupplierCustomerIconBase);
const ProductIcon = withIconProps(ProductIconBase);
const PaymentIcon = withIconProps(PaymentIconBase);
const LogsIcon = withIconProps(LogsIconBase);
const ChartLineIcon = withIconProps(ChartLineIconBase);
const WaterIcon = withIconProps(WaterIconBase);
const BullhornIcon = withIconProps(BullhornIconBase);
const CogIcon = withIconProps(CogIconBase);
const DatabaseIcon = withIconProps(DatabaseIconBase);
const AlertsIcon = withIconProps(AlertsIconBase);
const StatusIcon = withIconProps(StatusIconBase);
const FireIcon = withIconProps(FireIconBase);
const DotsIcon = withIconProps(DotsIconBase);
const StarIcon = withIconProps(StarIconBase);
const LogoIcon = withIconProps(LogoIconBase);
const LogoutIcon = withIconProps(LogoutIconBase);
const NoteBookIcon = withIconProps(NoteBookIconBase);
const NoteBookIconServer = withIconProps(NoteBookIconServerBase);
const AddIcon = withIconProps(AddIconBase);
const WalletIcon = withIconProps(WalletIconBase);
const ClockIcon = withIconProps(ClockIconBase);
const CartIcon = withIconProps(CartIconBase);
const ExchangeIcon = withIconProps(ExchangeIconBase);
const PencilIcon = withIconProps(PencilIconBase);
const RobotIcon = withIconProps(RobotIconBase);
const HedgeIcon = withIconProps(HedgeIconBase);








// Export all wrapped icons
export {
    ChevronDown,
    ChevronUp,
    DashboardIcon,
    TicketIcon,
    WaterMeterIcon,
    UsageIcon,
    RoleIcon,
    UserIcon,
    AlertIcon,
    SettingsIcon,
    DocumentIcon,
    SupplierCustomerIcon,
    ProductIcon,
    PaymentIcon,
    LogsIcon,
    ChartLineIcon,
    WaterIcon,
    BullhornIcon,
    CogIcon,
    DatabaseIcon,
    StatusIcon,
    AlertsIcon,
    FireIcon,
    DotsIcon,
    StarIcon,
    LogoIcon,
    LogoutIcon,
    ClockIcon,
    NoteBookIcon,
    AddIcon,
    WalletIcon,
    CartIcon,
    NoteBookIconServer,
    ExchangeIcon,
    PencilIcon,
    RobotIcon,
    HedgeIcon
};
