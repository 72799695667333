import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  Grid,
  TextField,
  Button,
  Card,
  CardContent,
  Typography,
} from "@material-ui/core";
import { NoteBookIconServer } from "../Components/Icons";
import axios from "axios";
import { MarketDropdown } from "../Components/Form/MarketDropdown";
import BluePrintPanel from "../Components/BluePrintPanel";
import { useParams } from "react-router-dom";
import CreateBluePrintForm from "../Components/Form/CreateBluePrintForm";
import { AnalyticsCards } from "../Components/AnalyticsCards";
import { ProfileCard } from "../Components/Cards/ProfileCard";

const BluePrint = () => {
  const { id } = useParams();

  return (
    <div className="dashboard-container">
      <AnalyticsCards />

      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          <Card className="hedge-card">
            <CardContent>
              <CreateBluePrintForm />
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={6}>
          <BluePrintPanel />
        </Grid>

        <Grid item xs={12} md={3}>
          <ProfileCard />
        </Grid>
      </Grid>
    </div>
  );
};

export default BluePrint;
