import React from 'react';
import { iconStyle } from './IconStyles'; // Adjust the import path as needed

export const ChevronUp: React.FC = () => {
  return (
    <svg
      {...iconStyle}
      fill="#fff"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 407.436 407.436"
    >
      <polygon points="203.718,91.567 0,294.621 21.179,315.869 203.718,133.924 386.258,315.869 407.436,294.621" />
    </svg>
  );
};

