import React, { useState } from 'react';
import ReactFlow, { addEdge, Controls, Background, MiniMap, Node, Edge, ReactFlowProvider } from 'reactflow';
import 'reactflow/dist/style.css';

// Mock API calls
const fetchCurrentPrice = async (pair: string) => {
  return new Promise((resolve) => setTimeout(() => resolve(50000), 1000));
};

// Node types for ReactFlow
const nodeTypes = {
  inputNode: ({ id, data }: { id: string, data: any }) => (
    <div style={{ padding: 10, border: '1px solid #ddd', borderRadius: 5, backgroundColor: '#fff' }}>
      <label style={{ color: '#000', fontWeight: 'bold' }}>{data.label}</label>
      <input
        type="text"
        value={data.value || ''}
        onChange={(e) => data.onChange(id, e.target.value)}
        placeholder={data.placeholder}
        style={{ width: '100%', padding: '8px', marginTop: '5px', color: '#000', border: '1px solid #ddd', borderRadius: 3 }}
      />
    </div>
  ),
  buttonNode: ({ data }: { data: any }) => (
    <div style={{ padding: 10, border: '1px solid #ddd', borderRadius: 5, backgroundColor: '#fff' }}>
      <button
        onClick={data.onClick}
        style={{
          backgroundColor: '#4CAF50',
          color: 'white',
          padding: '10px 20px',
          border: 'none',
          borderRadius: '5px',
          cursor: 'pointer',
        }}
      >
        {data.label}
      </button>
    </div>
  ),
  infoNode: ({ data }: { data: any }) => (
    <div style={{ padding: 10, border: '1px solid #ddd', borderRadius: 5, backgroundColor: '#f7f7f7', color: '#333' }}>
      <div>{data.label}: {data.value}</div>
    </div>
  ),
};

// Initial nodes and edges
const initialNodes: Node[] = [
  { id: '1', type: 'infoNode', data: { label: 'Welcome to HedgeAI! I am here to help you create a trading blueprint.' }, position: { x: 100, y: 100 } },
  { id: '2', type: 'buttonNode', data: { label: 'Start Blueprint Creation', onClick: null }, position: { x: 100, y: 200 } },
];

const initialEdges: Edge[] = [];

const Flow = () => {
  const [nodes, setNodes] = useState<Node[]>(initialNodes);
  const [edges, setEdges] = useState<Edge[]>(initialEdges);

  // Function to handle state updates for input changes
  const handleNodeInputChange = (nodeId: string, value: string) => {
    setNodes((nds) =>
      nds.map((node) =>
        node.id === nodeId ? { ...node, data: { ...node.data, value } } : node
      )
    );
  };

  // Start blueprint creation
  const handleStartBlueprint = () => {
    const inputNode = {
      id: '3',
      type: 'inputNode',
      data: {
        label: 'Enter Trading Pair and Blueprint Type',
        value: '',
        placeholder: 'e.g., BTC/USDT, Day Trading',
        onChange: handleNodeInputChange,
      },
      position: { x: 100, y: 300 },
    };

    const submitButton = {
      id: '4',
      type: 'buttonNode',
      data: { label: 'Submit', onClick: handleSubmitTradingPair },
      position: { x: 100, y: 400 },
    };

    setNodes((nds) => [...nds, inputNode, submitButton]);

    // Add edges between nodes
    const edgesList: Edge[] = [
      { id: 'e1', source: '1', target: '2', animated: true },
    ];
    setEdges((eds) => [...eds, ...edgesList]);
  };

  // Handle submit of trading pair
  const handleSubmitTradingPair = async () => {
    const inputNode = nodes.find((node) => node.id === '3');
    if (!inputNode) return;

    const tradingPair = inputNode.data.value.split(',')[0].trim();
    if (!tradingPair) return;

    const price = await fetchCurrentPrice(tradingPair);

    const pairNode = {
      id: '5',
      type: 'infoNode',
      data: { label: 'Trade Pair', value: tradingPair },
      position: { x: 400, y: 100 },
    };

    const priceNode = {
      id: '6',
      type: 'infoNode',
      data: { label: 'Current Price', value: `$${price}` },
      position: { x: 400, y: 200 },
    };

    const stopLossNode = {
      id: '7',
      type: 'inputNode',
      data: { label: 'Set Stop Loss', value: '', placeholder: 'Enter Stop Loss', onChange: handleNodeInputChange },
      position: { x: 100, y: 500 },
    };

    const entryPriceNode = {
      id: '8',
      type: 'inputNode',
      data: { label: 'Set Entry Price', value: '', placeholder: 'Enter Entry Price', onChange: handleNodeInputChange },
      position: { x: 100, y: 600 },
    };

    const takeProfitNode = {
      id: '9',
      type: 'inputNode',
      data: { label: 'Set Take Profit', value: '', placeholder: 'Enter Take Profit', onChange: handleNodeInputChange },
      position: { x: 100, y: 700 },
    };

    setNodes((nds) => [...nds, pairNode, priceNode, stopLossNode, entryPriceNode, takeProfitNode]);

    // Add edges between new nodes
    const newEdges: Edge[] = [
      { id: 'e2', source: '2', target: '3', animated: true },
      { id: 'e3', source: '3', target: '5', animated: true },
      { id: 'e4', source: '5', target: '6', animated: true },
      { id: 'e5', source: '6', target: '7', animated: true },
      { id: 'e6', source: '6', target: '8', animated: true },
      { id: 'e7', source: '6', target: '9', animated: true },
    ];

    setEdges((eds) => [...eds, ...newEdges]);
  };

  return (
    <ReactFlowProvider>
      <div style={{ height: '100vh', width: '100%' }}>
        <ReactFlow nodes={nodes} edges={edges} nodeTypes={nodeTypes} draggable>
          <Controls />
          <Background />
          <MiniMap />
        </ReactFlow>
        {nodes[1]?.data?.onClick === null && (
          <button
            onClick={handleStartBlueprint}
            style={{ position: 'absolute', bottom: 20, left: '50%', transform: 'translateX(-50%)', padding: '10px 20px', backgroundColor: '#4CAF50', color: 'white', border: 'none', borderRadius: '5px', cursor: 'pointer' }}
          >
            Start Blueprint Creation
          </button>
        )}
      </div>
    </ReactFlowProvider>
  );
};

export default Flow;
