import React, { SVGProps } from "react";

export const StarIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke={props.color}
      {...props}
      
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="1"></g>
      <g
        id="SVGRepo_tracerCarrier"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></g>
      <g id="SVGRepo_iconCarrier">
        {" "}
        <path
          d="M11.3174 3.61183C11.543 3.24404 11.6558 3.06014 11.8009 2.99739C11.9275 2.9426 12.0712 2.9426 12.1979 2.99739C12.3429 3.06014 12.4557 3.24404 12.6813 3.61183L14.6663 6.84813C14.7513 6.98682 14.7939 7.05616 14.8519 7.10659C14.9032 7.15121 14.9631 7.1849 15.0279 7.20557C15.1012 7.22893 15.1825 7.22924 15.3452 7.22986L19.2503 7.24464C19.7117 7.24638 19.9425 7.24726 20.0741 7.34454C20.1889 7.42937 20.2619 7.55921 20.2748 7.70135C20.2897 7.86435 20.1707 8.062 19.9326 8.45732L18.0479 11.5873C17.9575 11.7373 17.9124 11.8123 17.8947 11.8924C17.8791 11.9633 17.8791 12.0367 17.8947 12.1075C17.9124 12.1876 17.9575 12.2626 18.0479 12.4127L19.9326 15.5426C20.1707 15.9379 20.2897 16.1356 20.2748 16.2986C20.2619 16.4407 20.1889 16.5706 20.0741 16.6554C19.9425 16.7527 19.7117 16.7536 19.2503 16.7553L15.3452 16.7701C15.1825 16.7707 15.1012 16.771 15.0279 16.7944C14.9631 16.815 14.9032 16.8487 14.8519 16.8934C14.7939 16.9438 14.7513 17.0131 14.6663 17.1518L12.6813 20.3881C12.4557 20.7559 12.3429 20.9398 12.1979 21.0026C12.0712 21.0573 11.9275 21.0573 11.8009 21.0026C11.6558 20.9398 11.543 20.7559 11.3174 20.3881L9.33246 17.1518C9.2474 17.0131 9.20487 16.9438 9.14686 16.8934C9.09552 16.8487 9.03562 16.815 8.97081 16.7944C8.89759 16.771 8.81624 16.7707 8.65354 16.7701L4.74844 16.7553C4.28699 16.7536 4.05627 16.7527 3.92465 16.6554C3.80987 16.5706 3.73682 16.4407 3.72389 16.2986C3.70907 16.1356 3.82809 15.9379 4.06613 15.5426L5.95087 12.4127C6.0412 12.2626 6.08637 12.1876 6.10401 12.1075C6.11962 12.0367 6.11962 11.9633 6.10401 11.8924C6.08637 11.8123 6.0412 11.7373 5.95087 11.5873L4.06613 8.45731C3.82809 8.062 3.70907 7.86435 3.72389 7.70135C3.73682 7.55921 3.80987 7.42937 3.92465 7.34454C4.05627 7.24726 4.28699 7.24638 4.74844 7.24464L8.65354 7.22986C8.81624 7.22924 8.89759 7.22893 8.97081 7.20557C9.03562 7.1849 9.09552 7.15121 9.14686 7.10659C9.20487 7.05616 9.2474 6.98682 9.33246 6.84813L11.3174 3.61183Z"
          stroke=""
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        ></path>{" "}
      </g>
    </svg>
  );
};
