import React, { useState } from "react";
import { ApolloClient, InMemoryCache, ApolloProvider, createHttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import "./App.scss";
import { CssBaseline } from "@material-ui/core";
import { BrowserRouter as Router, Routes } from "react-router-dom";
import { SideMenu } from "./Components/SideMenu";
import TopBar from "./Components/TopBar";
import Footer from "./Components/Footer";
import { CustomThemeProvider } from "./Contexts/ThemeContext";
import { CREDENTIALS_LOCAL_STORAGE_ITEM } from "./constants";
import { CustomRoutes } from "./Components/CustomRoutes";
import { Web3ModalProvider } from "./Contexts/WagmiProvider";
import RightSidePanel from "./Components/RightSidePanel";

const httpLink = createHttpLink({
  uri: `${process.env.REACT_APP_SERVER_URL}/graphql`,
});


const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem(CREDENTIALS_LOCAL_STORAGE_ITEM);
  // console.log(token)
  return {
    headers: {
      ...headers,
      authorization: token ? token : "",
    },
  };
});

export const apolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink.concat(httpLink),
});

const App = (): React.ReactElement => {
  const [isSidebarMinimized, setIsSidebarMinimized] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarMinimized(!isSidebarMinimized);
  };

  return (
    <Web3ModalProvider>
    <ApolloProvider client={apolloClient}>
      <CustomThemeProvider>
        <CssBaseline />
        <Router>
          <div className="App">
            <TopBar toggleSidebar={toggleSidebar} isSidebarMinimized={isSidebarMinimized} />
            <div style={{ display: "flex" }}>
              <SideMenu isMinimized={isSidebarMinimized} />
              <div
                style={{
                  marginLeft: isSidebarMinimized ? "60px" : "15%",
                  
                }}
                className="dashboard-area"
              >
                <Routes>
                  {/* Route to BluePrintsMarket page */}
                  {/* <Route path="/blueprints" element={<BluePrintsMarket />} /> */}
                  {CustomRoutes}
                </Routes>
              </div>
            </div>
            <RightSidePanel/>
            <Footer />
          </div>
        </Router>
      </CustomThemeProvider>
    </ApolloProvider>
    </Web3ModalProvider>
  );
};

export default App;
