import { Typography, Grid, Card, CardContent } from "@material-ui/core";
// import { SwapWidget } from "@uniswap/widgets";
// import "@uniswap/widgets/fonts.css";
import React from "react";
import TradingViewChart from "../Components/Chart/TradingViewChart";

// const MY_TOKEN_LIST = [
//     {
//     "name": "Dai Stablecoin",
//     "address": "0x6B175474E89094C44Da98b954EedeAC495271d0F",
//     "symbol": "DAI",
//     "decimals": 18,
//     "chainId": 1,
//     "logoURI": "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x6B175474E89094C44Da98b954EedeAC495271d0F/logo.png"
//   },
//     {
//     "name": "Tether USD",
//     "address": "0xdAC17F958D2ee523a2206206994597C13D831ec7",
//     "symbol": "USDT",
//     "decimals": 6,
//     "chainId": 1,
//     "logoURI": "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xdAC17F958D2ee523a2206206994597C13D831ec7/logo.png"
//   },
//   {
//     "name": "USD Coin",
//     "address": "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
//     "symbol": "USDC",
//     "decimals": 6,
//     "chainId": 1,
//     "logoURI": "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48/logo.png"
//   },
// ]


const Swap = () => {
  return (
    <div className="dashboard-container">
      <Typography variant="h5" className="dashboard-header">
        Swap
      </Typography>

      <Grid container spacing={4}>
        <Grid item xs={12} md={4}>
          <Card className="hedge-card">
            <CardContent>
            {/* <SwapWidget tokenList={MY_TOKEN_LIST} /> */}
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={8}>
          <Card className="hedge-card">
            <CardContent>
                <TradingViewChart selectedPair="BTCUSDT" setSelectedPair={()=>{}}/>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default Swap;
