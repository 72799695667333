import { Grid } from "@material-ui/core";
import React from "react";
import { DashBoardCard } from "./Cards/DashBoardCard";
import { StarIcon, PencilIcon, CartIcon, WalletIcon } from "./Icons";

export const AnalyticsCards = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={6} md={3}>
        <DashBoardCard
          color={"#76D1F5"}
          leftLabel={"HedgeScore"}
          leftValue={"532"}
          rightLabel={"Trader Rank"}
          rightValue={"23"}
          icon={StarIcon}
        />
      </Grid>

      <Grid item xs={6} md={3}>
        <DashBoardCard
          color={"#9799A9"}
          leftLabel={"Published"}
          leftValue={"12"}
          rightLabel={"Success Rate"}
          rightValue={"77%"}
          icon={PencilIcon}
        />
      </Grid>

      <Grid item xs={6} md={3}>
        <DashBoardCard
          color={"#d476dc"}
          leftLabel={"Purchased"}
          leftValue={"5"}
          rightLabel={" Success Rate"}
          rightValue={"80%"}
          icon={CartIcon}
        />
      </Grid>

      <Grid item xs={6} md={3}>
        <DashBoardCard
          color={"#991181"}
          leftLabel={"ACA Earned"}
          leftValue={"11,200"}
          rightLabel={"Total Profit"}
          rightValue={"755%"}
          icon={WalletIcon}
        />
      </Grid>
    </Grid>
  );
};
