import React from "react";

interface AddIconProps {
  size?: number; // Size of the icon
  color?: string; // Color of the icon
  className?: string; // Additional classes
}

export const AddIcon: React.FC<AddIconProps> = (props) => (
  <svg
    {...props}
    viewBox="0 0 256 256"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M106.667 149.333H128M128 149.333H149.334M128 149.333V170.667M128 149.333V128"
      stroke="white"
      strokeWidth="16"
      strokeLinecap="round"
    />
    <path
      d="M21.3335 74.1303C21.3335 64.7167 21.3335 60.0098 22.0732 56.0892C25.3297 38.8296 38.8301 25.3292 56.0897 22.0727C60.0103 21.333 64.7172 21.333 74.1308 21.333C78.2553 21.333 80.3176 21.333 82.2996 21.5184C90.8444 22.3174 98.9497 25.6748 105.557 31.1518C107.089 32.4222 108.547 33.8805 111.464 36.797L117.333 42.6663C126.035 51.368 130.386 55.7188 135.596 58.6176C138.458 60.2099 141.495 61.4674 144.644 62.3653C150.378 63.9997 156.53 63.9997 168.836 63.9997H172.823C200.902 63.9997 214.94 63.9997 224.066 72.2073C224.906 72.9622 225.705 73.7612 226.459 74.6005C234.667 83.7261 234.667 97.7654 234.667 125.844V149.333C234.667 189.559 234.667 209.673 222.17 222.169C209.674 234.666 189.56 234.666 149.333 234.666H106.667C66.4403 234.666 46.3271 234.666 33.8302 222.169C21.3335 209.673 21.3335 189.559 21.3335 149.333V74.1303Z"
      stroke="white"
      strokeWidth="16"
    />
  </svg>
);
