import React from "react";
import HedgeAIFlow from "../Components/HedgeAIFlow";

const HedgeAI = () => {
  return (
    <div className="dashboard-container">
      <HedgeAIFlow />
    </div>
  );
};

export default HedgeAI;
