import React, { SVGProps } from 'react';

export const LogoIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      viewBox="0 0 2103 575"
      xmlns="http://www.w3.org/2000/svg"
    //   {...props} // Apply props directly
    // width={100}
    style={{width:"100px"}}
    >
      <style type="text/css">
        {`
          .st0{fill:url(#SVGID_1_);}
          .st1{fill:#F2F2F2;}
          .st2{fill:url(#SVGID_00000056397784828138160140000006326663387627150735_);}
        `}
      </style>
      <defs>
        <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="44" y1="274.7962" x2="465.2554" y2="274.7962">
          <stop offset="1.271566e-07" style={{stopColor: '#DA5AFF'}} />
          <stop offset="1" style={{stopColor: '#6ECDFC'}} />
        </linearGradient>
        <linearGradient id="SVGID_00000087410819122378323680000017837338234847944874_" gradientUnits="userSpaceOnUse" x1="1241.5702" y1="316.1303" x2="2060" y2="316.1303">
          <stop offset="1.271566e-07" style={{stopColor: '#DA5AFF'}} />
          <stop offset="1" style={{stopColor: '#6ECDFC'}} />
        </linearGradient>
      </defs>
      <path className="st0" d="M215.2,480.63c-0.45-0.4-0.55-0.49-1-0.88c2.37-10.65,4.43-21.38,7.23-31.92 c9.74-36.72,19.73-73.38,29.66-110.06c3.35-12.38,6.81-24.73,10.39-37.7c15.92-3.97,31.54-7.87,49.08-12.24 c-11.66,43.59-22.8,85.24-34.38,128.54c17.64-2.3,32.86-8.54,48.64-12.26c16.05-3.78,31.9-8.38,48.06-12.69 c14.38-53.48,28.58-106.3,43.05-160.15c-22.96-22.91-46.04-45.95-70.4-70.25c-8.38,25.13-13.34,49.61-20.93,73.65 c-15.7,3.79-31.04,7.48-48.68,11.74c15.45-58.3,30.44-114.4,45.58-169.93l143.75,143.44c-18.12,67.49-36.85,137.27-55.72,207.57 c-8.4,2.41-16.39,4.83-24.45,6.99c-27.85,7.43-55.74,14.72-83.6,22.15c-27.33,7.3-54.64,14.69-81.95,22.09 C218.03,479.12,216.67,480.05,215.2,480.63z M44,331.37c1.91,1.64,3.92,3.17,5.7,4.93c42.86,42.64,85.69,85.31,128.55,127.95 c2.08,2.07,4.38,3.91,8.01,7.14c14.84-55.2,29.21-108.66,44.02-163.75c-17.41,4.3-33.26,8.22-48.79,12.06 c-6.23,22.59-12.14,43.99-18.25,66.16c-23.47-23.5-45.92-45.98-68.58-68.66c14.4-53.63,28.66-106.71,43.03-160.21 c30.82-8.29,61.24-16.47,93.31-25.1c-3.14,16.4-7.59,30.66-11.47,45.06c-3.93,14.57-7.67,29.18-11.47,43.79 c-3.75,14.41-8.89,28.51-11.31,45.38c17.38-4.42,32.87-8.35,48.04-12.21c16.64-61.98,32.89-122.52,49.65-184.94 c-66.01,17.51-130.01,34.49-194.23,51.53c-3.19,11.68-6.26,22.68-9.21,33.71c-14.22,53.17-28.39,106.36-42.66,159.52 c-1.15,4.29-2.88,8.42-4.34,12.62C44,328.01,44,329.69,44,331.37z"/>
      <g>
        <path className="st1" d="M651.28,235.69L612.5,396.57h-37.23l15.9-65.96h-73.09l-15.9,65.96h-37.23l38.78-160.88h37.23l-15.29,63.43 h73.09l15.29-63.43H651.28z"/>
        <path className="st1" d="M773.23,366.69l-7.2,29.88H641.46l38.78-160.88h121.58l-7.2,29.88h-84.58l-8.42,34.93h74.69l-6.98,28.96 h-74.69l-8.97,37.23H773.23z"/>
        <path className="st1" d="M825.26,235.69h73.09c52.63,0,81.07,31.72,69.32,80.44c-11.74,48.72-55.47,80.44-108.1,80.44h-73.09 L825.26,235.69z M865.09,366c31.95,0,57.46-19.08,64.88-49.87c7.42-30.8-8.89-49.87-40.84-49.87h-34.01L831.08,366H865.09z"/>
        <path className="st1" d="M1095.83,313.6h34.01l-15.73,65.27c-20.91,13.33-46.99,20.45-69.97,20.45c-50.56,0-79.43-34.7-67.74-83.2 c11.69-48.49,57.29-83.2,108.54-83.2c28.27,0,49.38,9.65,60.64,27.81l-29.22,22.06c-8.73-12.41-20.9-18.16-37.22-18.16 c-31.26,0-57.67,20.91-65.04,51.48c-7.26,30.11,8.96,51.48,39.76,51.48c10.11,0,19.98-1.84,30.62-6.89L1095.83,313.6z"/>
        <path className="st1" d="M1234.65,367.57l-6.27,28.89h-93.25l38.78-160.78h87.91l-7.31,30.13h-50.8l-8.42,35h52.75l-5.94,27.92h-53.8 l-8.97,38.84H1234.65z"/>
      </g>
      <path style={{ fill: 'url(#SVGID_00000087410819122378323680000017837338234847944874_)' }} d="M1652.22,234.89c-65.41,0-132.01,18.04-191.97,37.58c-28.84,9.58-56.12,18.66-79.64,23.4c-18.71,3.45-37.4,1.44-53.1-6.38 c-19.07-9.18-28.35-24.4-29.29-45.98c-0.64-12.95,4.46-24.56,11.36-35.67c5.91-10.38,13.67-19.63,22.95-27.6c9.28-7.97,19.54-14.84,31.29-19.85 c9.26-3.49,18.65-5.43,28.21-5.43c28.87,0,52.48,12.84,67.81,31.92c14.43,19.08,19.82,40.7,18.74,65.78c-0.27,5.9-1.02,11.71-2.12,17.28 c-1.61,8.43-5.77,16.01-11.3,22.46c-6.95,7.18-15.82,12.75-25.45,16.64c-19.26,7.76-39.54,12.52-60.6,14.83 c-10.4,1.18-20.85,1.38-31.28,0.55c-18.11-1.59-36.58-8.22-51.81-18.66c-4.64-2.67-8.9-5.9-12.73-9.65 c-12.18-12.45-22.89-26.46-30.67-41.77c-7.79-15.31-12.79-31.55-12.79-49.02c0-23.4,8.34-45.99,22.68-63.49 c11.66-13.94,25.68-25.46,41.52-33.96c15.83-8.5,32.71-12.95,50.3-12.95c37.58,0,72.56,19.5,92.92,52.49 c16.16,27.69,17.74,57.95,11.8,87.63c-0.93,4.8-2.56,9.54-4.91,14.12C1696.16,267.34,1680.09,254.52,1652.22,234.89z"/>
    </svg>
  );
};
