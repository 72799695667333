import React from "react";

interface RobotIconProps extends React.SVGProps<SVGSVGElement> {}

export const RobotIcon: React.FC<RobotIconProps> = (props) => {
  return (
    <svg {...props} viewBox="0 0 306 312" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M50.8357 10.6684C130.199 15.5313 166.038 8.98926 191.043 8.98926C214.298 8.98926 225.24 85.4652 215.683 108.048C206.672 129.341 53.331 130.196 46.1919 121.763C37.9363 112.008 42.7095 71.2752 42.7095 57.3012" stroke="white" strokeWidth="16" strokeLinecap="round" stroke-linejoin="round"/>
    <path d="M51.3319 146.694C-57.6596 247.485 74.2501 229.498 101.233 251.083" stroke="white" strokeWidth="16" strokeLinecap="round" stroke-linejoin="round"/>
    <path d="M51.3301 146.694C52.7917 169.702 54.164 221.697 53.263 228.898" stroke="white" strokeWidth="16" strokeLinecap="round" stroke-linejoin="round"/>
    <path d="M161.308 92.2395C161.308 87.9595 161.308 83.6785 161.308 79.3965" stroke="white" strokeWidth="16" strokeLinecap="round" stroke-linejoin="round"/>
    <path d="M130.299 93.2715C130.035 89.8835 130.3 82.1215 130.3 79.3965" stroke="white" strokeWidth="16" strokeLinecap="round" stroke-linejoin="round"/>
    <path d="M156.398 197.72C305.097 195.921 327.881 182.73 265.524 297.851" stroke="white" strokeWidth="16" strokeLinecap="round" stroke-linejoin="round"/>
    <path d="M238.55 301.448C149.81 297.85 89.851 330.229 131.223 220.504" stroke="white" strokeWidth="16" strokeLinecap="round" stroke-linejoin="round"/>
    <path d="M133.018 301.448C113.77 287.385 92.3022 276.213 73.6582 260.675" stroke="white" strokeWidth="16" strokeLinecap="round" stroke-linejoin="round"/>
    <path d="M171.395 146.156C172.024 161.562 172.594 176.898 172.594 192.324" stroke="white" strokeWidth="16" strokeLinecap="round" stroke-linejoin="round"/>
    <path d="M171.395 146.156C178.896 158.037 185.97 165.77 194.777 186.327" stroke="white" strokeWidth="16" strokeLinecap="round" stroke-linejoin="round"/>
    <path d="M33.1174 75.041C28.5996 76.222 24.0489 75.99 19.4414 76.41" stroke="white" strokeWidth="16" strokeLinecap="round" stroke-linejoin="round"/>
    <path d="M12.5935 65.4688C12.6539 73.7557 12.5918 81.9147 11.9102 90.0857" stroke="white" strokeWidth="16" strokeLinecap="round" stroke-linejoin="round"/>
    <path d="M230.741 71.6219C234.155 71.2679 237.589 70.8229 240.997 70.2549" stroke="white" strokeWidth="16" strokeLinecap="round" stroke-linejoin="round"/>
    <path d="M244.412 60.6826C245.382 66.1096 245.095 71.6126 245.095 77.0936" stroke="white" strokeWidth="16" strokeLinecap="round" stroke-linejoin="round"/>
    <path d="M178.768 72.4658C156.362 69.9928 134.657 71.1748 113.124 74.5678" stroke="white" strokeWidth="16" strokeLinecap="round" stroke-linejoin="round"/>
    </svg>
    
  );
};
